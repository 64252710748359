import axios from "axios";
import jwtDefaultConfig from "./jwtDefaultConfig";
import toast from "react-hot-toast";

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // ** For Refreshing Token
  subscribers = [];

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    // ** Request Interceptor
    axios.interceptors.request.use(
      (config) => {
        config.headers["Access-Control-Allow-Origin"] = "*";

        // ** Get token from localStorage
        const accessToken = this.getToken();

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // ** const { config, response: { status } } = error
        const { config, response } = error;
        const originalRequest = config;

        // ** if (status === 401)
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true;
            this.refreshToken().then((r) => {
              this.isAlreadyFetchingAccessToken = false;

              // ** Update accessToken in localStorage
              this.setToken(r.data.data.access_token);
              this.setRefreshToken(r.data.refresh_token);

              this.onAccessTokenFetched(r.data.data.access_token);
            });
          }

          if (config.url == this.jwtConfig.refreshEndpoint) {
            toast.error(`${response.status} | ${response.statusText}`);
            localStorage.removeItem(this.jwtConfig.storageTokenKeyName);
            localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName);
            localStorage.removeItem("userData");
            localStorage.removeItem("kicked");
            window.location.href = "/login";
          }

          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              // ** Make sure to assign accessToken according to your response.
              // ** Check: https://pixinvent.ticksy.com/ticket/2413870
              // ** Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
              resolve(this.axios(originalRequest));
            });
          });
          return retryOriginalRequest;
        }

        // ** if (status === 403)
        if (response && response.status === 403) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true;
            toast.error(`${response.status} | ${response.statusText}`);
            if (response?.data?.data?.kicked) {
              toast.error(response.data.message);
              const kicked = JSON.parse(localStorage.getItem("kicked"));
              if (!kicked) {
                localStorage.setItem("kicked", JSON.stringify(response.data));
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            }
          }
        }

        return Promise.reject(error);
      }
    );
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    );
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return JSON.parse(localStorage.getItem(this.jwtConfig.storageTokenKeyName));
  }

  getRefreshToken() {
    return JSON.parse(
      localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
    );
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
  }

  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args);
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args);
  }

  refreshToken() {
    return axios.post(this.jwtConfig.refreshEndpoint, {
      refresh_token: this.getRefreshToken(),
    });
  }
}
