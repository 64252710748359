import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getRecords = createAsyncThunk(
  "masterTerjemah/getRecords",
  async (args, { getState }) => {
    const { masterTerjemah } = getState();

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API}/master/terjemah`,
      {
        params: {
          kata_asli: masterTerjemah?.filter?.kata_asli || "",
          kata_perbaikan: masterTerjemah?.filter?.kata_perbaikan || "",
          page: masterTerjemah?.page || 1,
          per_page: masterTerjemah?.perPage || 10,
        },
      }
    );

    return {
      data: response?.data?.data?.records || [],
      total: response?.data?.data?.total || 100,
    };
  }
);

export const masterTerjemahSlice = createSlice({
  name: "masterTerjemah",
  initialState: {
    isLoading: false,
    isOpenFilter: false,
    isOpenOffcanvas: false,
    offcanvasState: "",
    page: 1,
    perPage: 10,
    total: 0,
    filter: {
      kata_asli: "",
      kata_perbaikan: "",
    },
    records: [],
    record: {},
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsOpenFilter: (state, action) => {
      state.isOpenFilter = action.payload;
    },
    setIsOpenOffcanvas: (state, action) => {
      state.isOpenOffcanvas = action.payload;
    },
    setOffcanvasState: (state, action) => {
      state.offcanvasState = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setRecords: (state, action) => {
      state.records = action.payload;
    },
    setRecord: (state, action) => {
      state.record = action.payload;
    },
    setFilterKataAsli: (state, action) => {
      state.filter.kata_asli = action.payload;
    },
    setFilterKataPerbaikan: (state, action) => {
      state.filter.kata_perbaikan = action.payload;
    },
    handleClearFilter: (state) => {
      state.filter.kata_asli = "";
      state.filter.kata_perbaikan = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRecords.fulfilled, (state, action) => {
        state.records = action.payload.data;
        state.total = action.payload.total;
        state.isLoading = false;
      })
      .addCase(getRecords.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRecords.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  setIsLoading,
  setIsOpenFilter,
  setIsOpenOffcanvas,
  setOffcanvasState,
  setPage,
  setPerPage,
  setTotal,
  setFilter,
  setRecords,
  setRecord,
  setFilterKataAsli,
  setFilterKataPerbaikan,
  handleClearFilter,
} = masterTerjemahSlice.actions;

export default masterTerjemahSlice.reducer;
