import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getRecords = createAsyncThunk(
  "moduleBuku/getRecords",
  async (args, { getState }) => {
    const { moduleBuku } = getState();

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API}/module/buku`,
      {
        params: {
          judul_asli: moduleBuku?.filter?.judul_asli || "",
          konten_asli: moduleBuku?.filter?.konten_asli || "",
          judul_perbaikan: moduleBuku?.filter?.judul_perbaikan || "",
          konten_perbaikan: moduleBuku?.filter?.konten_perbaikan || "",
          page: moduleBuku?.page || 1,
          per_page: moduleBuku?.perPage || 10,
        },
      }
    );

    return {
      data: response?.data?.data?.records || [],
      total: response?.data?.data?.total || 100,
    };
  }
);

export const getRecord = createAsyncThunk(
  "moduleBuku/getRecord",
  async (args) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API}/module/buku/${args.id}`
    );

    return response?.data?.data;
  }
);

export const moduleBukuSlice = createSlice({
  name: "moduleBuku",
  initialState: {
    isLoading: false,
    isOpenFilter: false,
    isOpenOffcanvas: false,
    offcanvasState: "",
    page: 1,
    perPage: 10,
    total: 0,
    filter: {
      judul_asli: "",
      konten_asli: "",
      judul_perbaikan: "",
      konten_perbaikan: "",
    },
    records: [],
    record: {},
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsOpenFilter: (state, action) => {
      state.isOpenFilter = action.payload;
    },
    setIsOpenOffcanvas: (state, action) => {
      state.isOpenOffcanvas = action.payload;
    },
    setOffcanvasState: (state, action) => {
      state.offcanvasState = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setRecords: (state, action) => {
      state.records = action.payload;
    },
    setRecord: (state, action) => {
      state.record = action.payload;
    },
    setFilterJudulAsli: (state, action) => {
      state.filter.judul_asli = action.payload;
    },
    setFilterKontenAsli: (state, action) => {
      state.filter.konten_asli = action.payload;
    },
    setFilterJudulPerbaikan: (state, action) => {
      state.filter.judul_perbaikan = action.payload;
    },
    setFilterKontenPerbaikan: (state, action) => {
      state.filter.konten_perbaikan = action.payload;
    },
    handleClearFilter: (state) => {
      state.filter.judul_asli = "";
      state.filter.konten_asli = "";
      state.filter.judul_perbaikan = "";
      state.filter.konten_perbaikan = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRecords.fulfilled, (state, action) => {
        state.records = action.payload.data;
        state.total = action.payload.total;
        state.isLoading = false;
      })
      .addCase(getRecords.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRecords.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getRecord.fulfilled, (state, action) => {
        state.record = action.payload;
        state.isLoading = false;
      })
      .addCase(getRecord.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRecord.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  setIsLoading,
  setIsOpenFilter,
  setIsOpenOffcanvas,
  setOffcanvasState,
  setPage,
  setPerPage,
  setTotal,
  setFilter,
  setRecords,
  setRecord,
  setFilterJudulAsli,
  setFilterKontenAsli,
  setFilterJudulPerbaikan,
  setFilterKontenPerbaikan,
  handleClearFilter,
} = moduleBukuSlice.actions;

export default moduleBukuSlice.reducer;
