// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

export const getSuggestions = createAsyncThunk(
  "layout/getSuggestions",
  async (args, { getState }) => {
    const { navbar } = getState();

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API}/search/${navbar.query}`
    );

    return response?.data?.data;
  }
);

export const getNotifications = createAsyncThunk(
  "layout/getNotifications",
  async (args) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API}/notification`
    );

    return response?.data?.data;
  }
);

export const readNotification = createAsyncThunk(
  "layout/readNotification",
  async (args) => {
    await axios.post(
      `${process.env.REACT_APP_BASE_API}/notification/${args.id}/read`
    );
  }
);

export const readAllNotification = createAsyncThunk(
  "layout/readAllNotification",
  async (args) => {
    await axios.post(`${process.env.REACT_APP_BASE_API}/notification/read-all`);
  }
);

export const layoutSlice = createSlice({
  name: "layout",
  initialState: {
    query: "",
    newNotif: 0,
    suggestions: [],
    notifications: [],
  },
  reducers: {
    handleSearchQuery: (state, action) => {
      state.query = action.payload;
    },
    setNewNotif: (state, action) => {
      state.newNotif = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSuggestions.fulfilled, (state, action) => {
        state.suggestions = action.payload;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.notifications = action.payload.notifications;
        state.newNotif = action.payload.new;
      });
  },
});

export const { handleSearchQuery } = layoutSlice.actions;

export default layoutSlice.reducer;
