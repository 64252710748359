import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getRecords = createAsyncThunk(
  "masterExample/getRecords",
  async (args, { getState }) => {
    const { masterExample } = getState();

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API}/master/examples`,
      {
        params: {
          name: masterExample?.filter?.name || "",
          description: masterExample?.filter?.description || "",
          page: masterExample?.page || 1,
          per_page: masterExample?.perPage || 10,
        },
      }
    );

    return {
      data: response?.data?.data?.records || [],
      total: response?.data?.data?.total || 100,
    };
  }
);

export const masterExampleSlice = createSlice({
  name: "masterExample",
  initialState: {
    isLoading: false,
    isOpenFilter: false,
    isOpenOffcanvas: false,
    offcanvasState: "",
    page: 1,
    perPage: 10,
    total: 0,
    filter: {
      name: "",
      description: "",
    },
    records: [],
    record: {},
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsOpenFilter: (state, action) => {
      state.isOpenFilter = action.payload;
    },
    setIsOpenOffcanvas: (state, action) => {
      state.isOpenOffcanvas = action.payload;
    },
    setOffcanvasState: (state, action) => {
      state.offcanvasState = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setRecords: (state, action) => {
      state.records = action.payload;
    },
    setRecord: (state, action) => {
      state.record = action.payload;
    },
    setFilterName: (state, action) => {
      state.filter.name = action.payload;
    },
    setFilterDescription: (state, action) => {
      state.filter.description = action.payload;
    },
    handleClearFilter: (state) => {
      state.filter.name = "";
      state.filter.description = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRecords.fulfilled, (state, action) => {
        state.records = action.payload.data;
        state.total = action.payload.total;
        state.isLoading = false;
      })
      .addCase(getRecords.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRecords.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  setIsLoading,
  setIsOpenFilter,
  setIsOpenOffcanvas,
  setOffcanvasState,
  setPage,
  setPerPage,
  setTotal,
  setFilter,
  setRecords,
  setRecord,
  setFilterName,
  setFilterDescription,
  handleClearFilter,
} = masterExampleSlice.actions;

export default masterExampleSlice.reducer;
